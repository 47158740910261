import Grid from "@material-ui/core/Grid";
import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
export default function Preload() {
  return (
    <Grid container justify="center">
      <CircularProgress />
    </Grid>
  );
}
