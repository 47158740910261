import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
import GuestLayout from "./GuestLayout";
// import MainLayout from "./MainLayout";
// import FullScreenLayout from "./FullScreenLayout";
import Preload from "./Preload";
const MainLayout = React.lazy(() => import("./MainLayout"));
const FullScreenLayout = React.lazy(() => import("./FullScreenLayout"));
const guest_layouts = /^(\/logout|\/login|\/forgot-password|\/new-password)$/;
const full_screen_layouts = /^(\/preview\/)/;

export default function Layout() {
  const location = useLocation();

  if (location.pathname === "/") {
    window.location.replace("/dashboard");
  }
  if (guest_layouts.test(location.pathname)) {
    return <GuestLayout />;
  } else if (full_screen_layouts.test(location.pathname)) {
    return (
      <Suspense fallback={<Preload />}>
        <FullScreenLayout />
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Preload />}>
      <MainLayout />
    </Suspense>
  );
}
