import React from "react";
import ErrorToast from "components/toast/ErrorToast";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <Dialog
            aria-labelledby="information-popup"
            open={true}
            maxWidth="xs"
            fullWidth={true}
          >
            <DialogContent id="information-popup">
              <div style={{ textAlign: "center", marginBottom: 10 }}>
                <ErrorOutlineIcon
                  style={{ fontSize: "8rem", color: "#ffb1c0" }}
                />
              </div>
              <Typography variant="body1" color="textPrimary" align="center">
                An error occurred while loading page. Please refresh the page
                and try again
              </Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                color="primary"
                style={{ marginBottom: 15 }}
                variant="outlined"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh Page
              </Button>
            </DialogActions>
          </Dialog>
          {/* <ErrorToast
            error={this.state.error}
            processCustomError={() => `Unable to load data - ${error?.message}`}
          /> */}
        </>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
