import { Order_By } from "generated/graphql";

export const capitalize = (words: string, split: string = " ") =>
  words
    .split(split)
    .map(
      (word) => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase()
    )
    .join(" ");

export const username_regx = /^[A-Za-z0-9]{2,15}(?:[_-][A-Za-z0-9]+)*$/;
export const name_regx = /^([a-zA-Z0-9'_-]+\s)*[a-zA-Z0-9'_-]+$/;
export const email_regx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const user_roles_regx = /^(teacher|student|parent|school_admin)$/;

export const direction = {
  asc: Order_By.Asc,
  desc: Order_By.Desc,
};
