import React, { useContext } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "components/form/Input";
import Alert from "@material-ui/lab/Alert";
//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Amplify, { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { username_regx } from "utils";
import { UserContext } from "./UserContex";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    storage: localStorage,
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      maxWidth: 320,
      width: "100%",
      "& button": {
        boxShadow: "none !important",
        borderRadius: 3,
        padding: "8px 16px",
      },
      "& [class*='MuiInputBase-root']": {
        borderRadius: 3,
      },
    },
    inputStyle: {},
    switchFormBtn: {
      cursor: "pointer",
      display: "inline-block",
      fontSize: 14,
      color: theme.palette.grey[500],
      textDecoration: "none",
      opacity: 0.8,
      "&:hover": {
        opacity: 1,
      },
    },
  })
);

type loginForm = {
  username: string;
  password: string;
};

type error = {
  name?: string;
  message?: string;
  code?: string;
};

const SignInschema = yup.object().shape({
  username: yup
    .string()
    .required("Username is required")
    .matches(username_regx, "Invalid username"),

  password: yup.string().required("Password is required"),
});

export default function Login() {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<error>({});

  Auth.currentSession()
    .then((res) => {
      if (res) {
        history.push("/select_trip_type");
      }
    })
    .catch((e) => {
      console.log(e);
    });

  const form = useForm<loginForm>({
    resolver: yupResolver(SignInschema),
  });

  const newUser = useContext(UserContext);

  const { handleSubmit } = form;

  const onSubmit = async (data: loginForm) => {
    setLoading(true);
    setError({});
    try {
      const user = await Auth.signIn(data.username, data.password);

      if (
        user.challengeName &&
        user.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        newUser.setnewUser(user);
        history.push("/new-password");
      } else {
        history.push("/select_trip_type");
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return (
    <AuthLayout>
      <div className={classes.formWrapper}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              variant="body1"
              color="textSecondary"
              gutterBottom={true}
              style={{ marginBottom: 10 }}
            >
              Sign in with your username and password
            </Typography>
            <div className={classes.inputStyle}>
              <InputField name="username" label="Username" />
            </div>
            <div className={classes.inputStyle}>
              <InputField name="password" label="Password" type="password" />
            </div>

            <Link
              to="/forgot-password"
              className={classes.switchFormBtn}
              style={{ marginBottom: 10 }}
            >
              Forgot your password?
            </Link>
            {error?.message && (
              <Alert severity="error" style={{ marginBottom: 10 }}>
                {error?.message}
              </Alert>
            )}

            <Button
              variant="contained"
              fullWidth={true}
              type="submit"
              color="primary"
              disabled={loading}
            >
              {loading ? "Please wait..." : "Login"}
            </Button>
          </form>
        </FormProvider>
      </div>
    </AuthLayout>
  );
}
