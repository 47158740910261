import React, { Suspense } from "react";
import LoginScreen from "../login/Login";
import { Switch, Route } from "react-router-dom";
import "../../App.css";
import { UserProvider } from "../login/UserContex";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";

const Logout = React.lazy(() => import("../user/Logout"));
const ForgotPassword = React.lazy(() => import("../login/ForgotPassword"));
const NewPassword = React.lazy(() => import("../login/NewPassword"));

export const guestRoutes = [
  { path: "/logout", component: Logout },
  //{ path: "/login", component: LoginScreen },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/new-password", component: NewPassword },
];

export default function GuestLayout() {
  function CreateComponent(props) {
    const Component = props.component;

    return <Route path={props.path} render={() => <Component />} />;
  }
  return (
    <section id="wrapper" className="login-register">
      <div className="login-box login-sidebar">
        <div className="white-box">
          <div className="form-horizontal form-material">
            {/* <div className={"text-center db"}>
              <img src={"/images/mft-logo-text.jpg"} alt={"Logo"} width={300} />
            </div> */}

            <Switch>
              <UserProvider>
                <Route path="/login" component={LoginScreen} />
                <Suspense
                  fallback={
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      style={{ minHeight: "100vh" }}
                    >
                      <CircularProgress color="primary" />
                    </Grid>
                  }
                >
                  {guestRoutes.map((route) => (
                    <CreateComponent
                      key={route.path}
                      path={route.path}
                      component={route.component}
                    />
                  ))}
                </Suspense>
              </UserProvider>
            </Switch>
          </div>
        </div>
      </div>
    </section>
  );
}
