import React, { createContext, useState } from "react";

export const UserContext = createContext<any>(null);

export const UserProvider = (props: any) => {
  const { children } = props;
  const [newUser, setnewUser] = useState(null);

  return (
    <UserContext.Provider
      value={{
        newUser,
        setnewUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
